import { ArrowRightStartOnRectangleIcon } from '@heroicons/react/24/outline';
import { useStytchB2BClient } from '@stytch/nextjs/b2b';

export const SidebarLogoutButton = () => {
  const stytch = useStytchB2BClient();

  const handleLogout = () => {
    stytch.session.revoke().finally(() => {
      window.location.reload();
    });
  };
  return (
    <button onClick={handleLogout}>
      <ArrowRightStartOnRectangleIcon className="h-5 w-5 text-tertiary hover:text-primary" />
    </button>
  );
};
