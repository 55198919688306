'use client';

import BaseLink from 'next/link';
import { useSearchParams } from 'next/navigation';
import { MouseEventHandler, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const UNIQUE_PARAM = 'u';

export const Link = ({
  children,
  href,
  preserveQuery = false,
  scroll,
  onClick,
  className,
  queryParams,
  unique = false,
}: {
  active?: boolean;
  children: React.ReactNode;
  href: string;
  preserveQuery?: boolean;
  scroll?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  className?: string;
  queryParams?: Record<string, string | undefined>;
  unique?: boolean;
}) => {
  const [u, setU] = useState(uuidv4());
  const query = useSearchParams();

  const params = useMemo(() => new URLSearchParams(query.toString()), [query]);

  if (params.has(UNIQUE_PARAM)) {
    params.delete(UNIQUE_PARAM);
  }

  if (!preserveQuery) {
    [...params.entries()].forEach(([key]) => {
      params.delete(key);
    });
  }

  if (unique) {
    params.set(UNIQUE_PARAM, u.toString());
  }

  if (queryParams) {
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value === undefined) {
        params.delete(key);
      } else {
        params.set(key, value);
      }
    });
  }

  const hrefWithParams = [href, params.toString()].filter(Boolean).join('?');

  return (
    <BaseLink
      href={hrefWithParams}
      scroll={scroll}
      className={className}
      onClick={(...params) => {
        if (unique) setU(uuidv4());
        if (onClick) onClick(...params);
      }}
    >
      {children}
    </BaseLink>
  );
};
