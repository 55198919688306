'use client';
import clsx from 'clsx';
import Image from 'next/image';
import React, { useState } from 'react';

import covAvatarWhite from '@/public/images/covy/avatar-white.png';
import { Logo } from '@/src/app/_components/Icons';
import { SidebarNav } from '@/src/app/_components/layout/sidebar/SidebarNav';
import { Link } from '@/src/app/_components/ui/Link';
import { BrandLogo } from '@/src/common/components/BrandLogo';
import { Brand, SessionUser } from '@/types/common';

import { SidebarLogoutButton } from './SidebarLogoutButton';

export const Sidebar = ({
  user,
  brand,
}: {
  user: SessionUser;
  brand?: Brand;
}) => {
  const [isExpanded, setExpanded] = useState(false);

  if (!brand) return;

  return (
    <div
      className={clsx(
        'group fixed z-50 flex h-screen shrink-0 flex-col overflow-hidden border-r border-secondary bg-primary transition-all ease-linear',
        { 'w-[61px]': !isExpanded, 'w-[225px]': isExpanded },
      )}
      onMouseEnter={() => setExpanded(true)}
      onMouseLeave={() => setExpanded(false)}
    >
      <Link
        href="/"
        className="w-max py-8"
        onClick={(e) => e.stopPropagation()}
      >
        {isExpanded ? (
          <div className="ml-[20px] mt-[1px] self-start transition-all">
            <Logo />
          </div>
        ) : (
          <Image
            src={covAvatarWhite}
            alt="covariance.ai"
            height={32}
            className="ml-[14px] self-start transition-all"
          />
        )}
      </Link>
      <div
        className={clsx('no-scrollbar mb-28 h-full px-1', {
          'overflow-y-auto': isExpanded,
        })}
      >
        <SidebarNav
          isExpanded={isExpanded}
          setExpanded={setExpanded}
          isAdmin={user.isAdmin}
          isCompanyAdmin={user.isCompanyAdmin}
        />
      </div>

      <div
        className={clsx(
          'absolute bottom-0 flex h-20 w-full items-center justify-between gap-lg justify-self-end bg-primary pb-9 pl-[11px] pt-6 text-primary',
          { 'pr-4': isExpanded },
        )}
      >
        {user.brandId && user.isAdmin ? (
          <Link
            href={`/admin/brands/${user.brandId}`}
            onClick={(e) => e.stopPropagation()}
            className="shrink-0"
          >
            <BrandLogo
              brand={{
                color: brand.color,
                imageName: brand?.imageName ?? null,
              }}
              size={10}
            />
          </Link>
        ) : (
          <BrandLogo
            brand={{ color: brand.color, imageName: brand?.imageName ?? null }}
            size={10}
            className="shrink-0"
          />
        )}
        {isExpanded && (
          <>
            <a
              href="#"
              className="hover:bg-active-1 flex grow items-center gap-x-4 truncate leading-6"
            >
              <div className="items-flex-start flex flex-col">
                <span
                  aria-hidden="true"
                  className="font-semibold text-secondary"
                >
                  {user.name.split(' ')[0]}
                </span>
                <span aria-hidden="true" className="text-sm text-tertiary">
                  {user.name.split(' ')[1]}
                </span>
              </div>
            </a>
            <SidebarLogoutButton />
          </>
        )}
      </div>
    </div>
  );
};
